<template>
  <vx-card title="Approval Customer Address">
    <vs-tabs>
      <vs-tab label="Open">
        <div class="tab-text">
          <data-table :statusApproval="'1'" :showButtonApproveReject="true"></data-table>
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="tab-text">
          <data-table :statusApproval="'2'" :showButtonApproveReject="false"></data-table>
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="tab-text">
          <data-table :statusApproval="'3'" :showButtonApproveReject="false"></data-table>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
  <script>
import DataTable from "./DataTable";

export default {
  components: {
    DataTable,
  },
};
</script>
  